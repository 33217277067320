import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import { DefaultOverlayComponent } from "../default-overlay/default-overlay.component";
import { SaveButtonComponent } from "../buttons/save-button/save-button.component";
import { Router } from "@angular/router";
import { EnvironmentStore } from "../../stores/environment/environment.store";
import { BroadcastChannelSender } from "../../helpers/broadcast-channel/BroadcastChannelSender.class";

@Component({
  selector: 'robocode-subscription-popup',
  standalone: true,
    imports: [
        DefaultOverlayComponent,
        SaveButtonComponent
    ],
  templateUrl: './subscription-popup.component.html',
  styleUrl: './subscription-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionPopupComponent {
    @Input() expirationDate: string | undefined;

    constructor(private router: Router) {
        let environmentStore = inject(EnvironmentStore);
        this.expirationDate = new Date(environmentStore.subscription().expiration * 1000).toLocaleDateString("ru-RU").toString();
    }

    OnProlongSubscriptionClick(): void {
        BroadcastChannelSender.postMessage('redirect_to_tariff_page', window.name);
    }
}
